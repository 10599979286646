:root {
    --bg: #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1;
    --nav-size: 60px;
    --border: 1px solid #474a4d;
    --border-radius: 8px;
    --speed: 500ms;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.link_style {
    color: var(--text-color);
    text-decoration: none;
}

.navbar {
    height: var(--nav-size);
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: var(--border);
}

.navbar_nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;

}

.nav_item {
    width: calc(var(--nav-size) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}