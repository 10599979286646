.activities {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 93.5vh;

    background: linear-gradient(rgba(94, 84, 125, 0.7),
            rgba(255, 255, 255, 0.3)),
        url('../../../src/assets/activities_bg3.jpg');
    background-size: cover;
    background-position: center;
}

form {
    background-color: rgba(245, 245, 245, 0.63);
    padding: 0em 3.75em;
    border-radius: 0.625em;
}

h2 {
    color: rgba(22, 3, 70, 0.7);
    text-align: center;
}

.formButton {
    width: 100%;
    height: 3em;
    padding: 0.625em;
    background-color: rgba(22, 3, 70, 0.7);
    color: rgb(233, 233, 233);
    border-radius: 0.625em;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.12em;
    margin-top: 1em;
    margin-bottom: 1.875em;
}

.box {
    display: flex;
    flex-direction: column;
    margin: .15em;
    font-size: 0.78em;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
}

.box select {
    background-color: rgba(22, 3, 70, 0.7);
    color: rgb(231, 225, 253);
    padding: 0.8em;
    width: 23.5em;
    border: none;
    margin: 0.5em 0.15em;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: button;
    outline: none;
}

.box:hover {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
    padding: 30px;
}