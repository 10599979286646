.CountryList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 3rem;
    padding: 0 3rem 3rem;
}

@media only screen and (min-width: 60em) {
    .CountryList {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
        margin: 3rem;
        padding: 0 3rem 3rem;
    }
}