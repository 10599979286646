.card {
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 0.35rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.card img {
    border-top-left-radius: 0.35rem;
    border-top-right-radius: 0.35rem;
    /* border-style: solid;
    border-color: #000000; */
    box-shadow: 1px 4px 6px -1px rgba(0, 0, 0, 0.1), 1px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.card_content {
    padding: 2rem;
}

.card_content h3 {
    margin-bottom: 2rem;
}

.img_fluid {
    width: 100%;
}