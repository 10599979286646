.detailContainer {
    background-color: rgba(245, 245, 245, 0.63);
    width: 100%;
    height: 100%;
    display: grid;
    grid:
        "header" auto "main" 1fr "footer" auto / 1fr;
    gap: 2em;
}

.header {
    grid-area: header;
    display: flex;
    justify-content: center;
    margin: 2em;
}

.main {
    grid-area: main;
    width: 100%;
    display: flex;
    gap: 5%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.mainInfo {
    flex-direction: row;
}

.footer {
    grid-area: footer;
    display: flex;
    justify-content: center;
    margin: 2em;
    text-decoration: none;
}