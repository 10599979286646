.country_home {
    width: 12rem;
    height: auto;
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}

.country_home .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #282936;
    border-radius: 1.625rem;
}

.country_home .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
}

.country_home .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
}

.country_home .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
}

.country_home .button_text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
}

.country_home:hover .circle {
    width: 100%;
}

.country_home:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
}

.country_home:hover .button-text {
    color: #fff;
}

.country_back {
    background: rgba(22, 3, 70, 0.7);
    color: rgb(231, 225, 253);
    font-family: inherit;
    padding: 0.6em 1.3em;
    font-weight: 900;
    font-size: 1.2em;
    border: 0.1em solid rgb(231, 225, 253);
    border-radius: 0.4em;
    box-shadow: 0.1em 0.1em;
    cursor: pointer;
    text-decoration: none;
}

.country_back:hover {
    transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em;
}

.country_back:active {
    transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
}