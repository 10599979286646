body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root {
    --bg: #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1;
    --nav-size: 60px;
    --border: 1px solid #474a4d;
    --border-radius: 8px;
    --speed: 500ms;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav_link_style__192Fj {
    color: #dadce1;
    color: var(--text-color);
    text-decoration: none;
}

.nav_navbar__2Q9mY {
    height: 60px;
    height: var(--nav-size);
    background-color: #242526;
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: 1px solid #474a4d;
    border-bottom: var(--border);
}

.nav_navbar_nav__3ydsh {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;

}

.nav_nav_item__20dME {
    width: calc(60px * 0.8);
    width: calc(var(--nav-size) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}
.formInput_formInput__1VZxu {
    display: flex;
    flex-direction: column;
}

input {
    padding: 1em 3em;
    margin: 0.625em 0em;
    width: 17.5em;
    border: 0.0625em solid rgb(77, 77, 77);
    border-radius: 0.3em;

}


label {
    font-size: .9em;
    color: rgb(77, 77, 77);
}

span {
    font-size: 0.78em;
    padding: 0.18em;
    color: red;
    display: none;
}

input:invalid[focused="true"] {
    border: 1px solid red;
}

input:invalid[focused="true"]~span {
    display: block;
}
.activities_activities__1BNjd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 93.5vh;

    background: linear-gradient(rgba(94, 84, 125, 0.7),
            rgba(255, 255, 255, 0.3)),
        url(/static/media/activities_bg3.a22df2d5.jpg);
    background-size: cover;
    background-position: center;
}

form {
    background-color: rgba(245, 245, 245, 0.63);
    padding: 0em 3.75em;
    border-radius: 0.625em;
}

h2 {
    color: rgba(22, 3, 70, 0.7);
    text-align: center;
}

.activities_formButton__UhqG_ {
    width: 100%;
    height: 3em;
    padding: 0.625em;
    background-color: rgba(22, 3, 70, 0.7);
    color: rgb(233, 233, 233);
    border-radius: 0.625em;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.12em;
    margin-top: 1em;
    margin-bottom: 1.875em;
}

.activities_box___GVr4 {
    display: flex;
    flex-direction: column;
    margin: .15em;
    font-size: 0.78em;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
}

.activities_box___GVr4 select {
    background-color: rgba(22, 3, 70, 0.7);
    color: rgb(231, 225, 253);
    padding: 0.8em;
    width: 23.5em;
    border: none;
    margin: 0.5em 0.15em;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: button;
    outline: none;
}

.activities_box___GVr4:hover {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
}

.activities_box___GVr4 select option {
    padding: 30px;
}
.countryCard_card__35izK {
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 0.35rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.countryCard_card__35izK img {
    border-top-left-radius: 0.35rem;
    border-top-right-radius: 0.35rem;
    /* border-style: solid;
    border-color: #000000; */
    box-shadow: 1px 4px 6px -1px rgba(0, 0, 0, 0.1), 1px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.countryCard_card_content__RGvk5 {
    padding: 2rem;
}

.countryCard_card_content__RGvk5 h3 {
    margin-bottom: 2rem;
}

.countryCard_img_fluid__3oIH_ {
    width: 100%;
}
.CountryList {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    gap: 2rem;
    margin: 3rem;
    padding: 0 3rem 3rem;
}

@media only screen and (min-width: 60em) {
    .CountryList {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 2rem;
        gap: 2rem;
        margin: 3rem;
        padding: 0 3rem 3rem;
    }
}
.pagination_Pagination__IsPEM {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pagination_PaginationItem__Fu1B0 {
    background: #fff;
    border: 2px solid #666;
    padding: 10px 15px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
}

.pagination_paginationItem__2V6yi span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.pagination_prev__RkQ_U,
.pagination_next__3yG7v {
    background: rgba(22, 3, 70, 0.7);
    border: none;
    padding: 1.2em;
    color: rgb(231, 225, 253);
    box-shadow: 0 0 .125em rgba(0, 0, 0, 0.4);
    margin: 0 .8em;
    cursor: pointer;
}

.pagination_paginationItem__2V6yi.pagination_active__3BcMY {
    border: 1px solid #888;
    color: #888;
    pointer-events: none;
}

.pagination_prev__RkQ_U.pagination_disabled__1gP-A,
.pagination_next__3yG7v.pagination_disabled__1gP-A {
    pointer-events: none;
    box-shadow: none;
    color: #999;
}
.search_search__3cAtj {
    width: 100%;
    position: relative;
    display: flex;
    margin: 1.5em;
}

.search_searchTerm__pIU2p {
    width: 100%;
    border: 0.125em solid rgba(22, 3, 70, 0.7);
    padding: 0.8em;
    height: 1.5em;
    border-radius: 0.25em 0.25em;
    color: rgba(22, 3, 70, 0.7);
}

.search_searchTerm__pIU2p:focus {
    color: rgba(22, 3, 70, 0.7);
}
:root {
    --bg: #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1;
    --nav-size: 60px;
    --border: 1px solid #474a4d;
    --border-radius: 8px;
    --speed: 500ms;
}

.dropdown_box__8CpKU {
    display: flex;
    flex-direction: column;
    margin: .15em;
    font-size: 0.78em;
    /* position: absolute; */
    /* top: 50%;
    left: 50%; */
    font-size: 1.2em;
    /* transform: translate(-50%, -50%); */
}

.dropdown_box__8CpKU select {
    background-color: rgba(22, 3, 70, 0.7);
    color: rgb(231, 225, 253);
    padding: 0.8em;
    width: 23.5em;
    font-size: .75em;
    border: none;
    margin: 0.5em 0.15em;
    box-shadow: 0 0.4em 2em rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: button;
    outline: none;
}

.dropdown_box__8CpKU:hover {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
}

.dropdown_box__8CpKU select option {
    padding: 2em;
}
.countries_countries__34y1Z {
    background-color: rgba(245, 245, 245, 0.63);
    /* background-color: rgba(50, 48, 56, 0.7), */
    /* background: linear-gradient(rgba(161, 159, 166, 0.7),
            rgba(81, 81, 81, 0.3)),
        url('../../../src/assets/fondeHome.jpg'); */
}

.countries_navCountries__333us {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.2em 5em;
}

.countries_navCountries2__2wB1r {
    flex-direction: row;
    justify-content: space-around;
    grid-gap: 4em;
    gap: 4em;
}

.countries_navCountries3__3LeSi {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}
.Buttons_country_home__-UZPx {
    width: 12rem;
    height: auto;
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}

.Buttons_country_home__-UZPx .Buttons_circle__1HVht {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #282936;
    border-radius: 1.625rem;
}

.Buttons_country_home__-UZPx .Buttons_circle__1HVht .Buttons_icon__1U5Vy {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
}

.Buttons_country_home__-UZPx .Buttons_circle__1HVht .Buttons_icon__1U5Vy.Buttons_arrow__1c4mg {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
}

.Buttons_country_home__-UZPx .Buttons_circle__1HVht .Buttons_icon__1U5Vy.Buttons_arrow__1c4mg::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.Buttons_country_home__-UZPx .Buttons_button_text__5rCrj {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
}

.Buttons_country_home__-UZPx:hover .Buttons_circle__1HVht {
    width: 100%;
}

.Buttons_country_home__-UZPx:hover .Buttons_circle__1HVht .Buttons_icon__1U5Vy.Buttons_arrow__1c4mg {
    background: #fff;
    -webkit-transform: translate(1rem, 0);
            transform: translate(1rem, 0);
}

.Buttons_country_home__-UZPx:hover .Buttons_button-text__3zIiZ {
    color: #fff;
}

.Buttons_country_back__2axH4 {
    background: rgba(22, 3, 70, 0.7);
    color: rgb(231, 225, 253);
    font-family: inherit;
    padding: 0.6em 1.3em;
    font-weight: 900;
    font-size: 1.2em;
    border: 0.1em solid rgb(231, 225, 253);
    border-radius: 0.4em;
    box-shadow: 0.1em 0.1em;
    cursor: pointer;
    text-decoration: none;
}

.Buttons_country_back__2axH4:hover {
    -webkit-transform: translate(-0.05em, -0.05em);
            transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em;
}

.Buttons_country_back__2axH4:active {
    -webkit-transform: translate(0.05em, 0.05em);
            transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
}
.countryDetail_detailContainer__1GlJR {
    background-color: rgba(245, 245, 245, 0.63);
    width: 100%;
    height: 100%;
    display: grid;
    grid:
        "header" auto "main" 1fr "footer" auto / 1fr;
    grid-gap: 2em;
    gap: 2em;
}

.countryDetail_header__1ErJw {
    grid-area: header;
    display: flex;
    justify-content: center;
    margin: 2em;
}

.countryDetail_main__3PufQ {
    grid-area: main;
    width: 100%;
    display: flex;
    grid-gap: 5%;
    gap: 5%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.countryDetail_mainInfo__1lwGc {
    flex-direction: row;
}

.countryDetail_footer__g9wmf {
    grid-area: footer;
    display: flex;
    justify-content: center;
    margin: 2em;
    text-decoration: none;
}
body {
    font-family: 'PT Sans Caption', "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    font-size: 2vh;
}

.home_hero__Nj50H {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 95vh;
    padding: 1em;
    box-sizing: border-box;
    color: rgb(231, 225, 253);
    background: linear-gradient(rgba(22, 3, 70, 0.7),
            rgba(37, 37, 37, 0.3)), url(/static/media/hero.6a57bb36.jpg) center center no-repeat;
    background-size: cover;
}

.home_hero_title__2ovom {
    max-width: 16em;
    margin: 0 .3em;
    font-size: 12vh;
    font-weight: bold;
    line-height: .9;
}

.home_p_title__2NQhM {
    margin: 1em 2em;
    font-size: 3vh;
}

@media only screen and (min-width: 32em) {
    .home_hero_title__2ovom {
        font-size: 16vh;
    }
}

.home_hero_footer__1_Yz1 {
    display: flex;
    margin: 0 3em;
}

.home_button__1vOGb {
    padding: .5em .67em;
    color: rgb(225, 218, 251);
    text-decoration: none;
    border: .1em solid rgb(225, 218, 251);

}

.home_button_primary__jnZ7x {
    color: black;
    background-color: rgb(225, 218, 251);
}
