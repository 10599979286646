body {
    font-family: 'PT Sans Caption', "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    font-size: 2vh;
}

.hero {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 95vh;
    padding: 1em;
    box-sizing: border-box;
    color: rgb(231, 225, 253);
    background: linear-gradient(rgba(22, 3, 70, 0.7),
            rgba(37, 37, 37, 0.3)), url('../../assets/hero.jpg') center center no-repeat;
    background-size: cover;
}

.hero_title {
    max-width: 16em;
    margin: 0 .3em;
    font-size: 12vh;
    font-weight: bold;
    line-height: .9;
}

.p_title {
    margin: 1em 2em;
    font-size: 3vh;
}

@media only screen and (min-width: 32em) {
    .hero_title {
        font-size: 16vh;
    }
}

.hero_footer {
    display: flex;
    margin: 0 3em;
}

.button {
    padding: .5em .67em;
    color: rgb(225, 218, 251);
    text-decoration: none;
    border: .1em solid rgb(225, 218, 251);

}

.button_primary {
    color: black;
    background-color: rgb(225, 218, 251);
}